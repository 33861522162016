
import './App.css';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import Homepage from './Pages/NewHomepage';
import Aboutus from './Pages/Newabout';
import Newcontact from './Pages/Newcontact'



function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/aboutus" element={<Aboutus />} />
          <Route path="/Contactus" element={<Newcontact />} />


        </Routes>
      </Router>

    </>
  );
}

export default App;
