import React from 'react'

const Newfooter = () => {
  return (
    <div>
      <div className="footer_all">
        <div className="footer row container mx-auto">
          <div className="col-md-2 col-12">
            <div className="address_sec">
              <svg xmlns="http://www.w3.org/2000/svg" width="94" height="36" viewBox="0 0 94 36" fill="none">
                <path d="M63.7891 6.94733V34.1052H74.3793C76.4152 34.1052 78.1496 33.7118 79.5826 32.925C81.0156 32.1471 82.1079 31.0641 82.8593 29.6762C83.6195 28.297 83.9996 26.7058 83.9996 24.9023C83.9996 23.0989 83.6151 21.5076 82.8462 20.1285C82.0773 18.7493 80.9632 17.6752 79.504 16.9061C78.0535 16.137 76.2972 15.7524 74.2351 15.7524H67.4852V20.3539H73.3176C74.4099 20.3539 75.3098 20.544 76.0176 20.9241C76.7341 21.3131 77.2671 21.8479 77.6166 22.5286C77.9749 23.2182 78.154 24.0094 78.154 24.9023C78.154 25.804 77.9749 26.5908 77.6166 27.2627C77.2671 27.9434 76.7341 28.4694 76.0176 28.8407C75.3011 29.2209 74.3924 29.4109 73.2914 29.4109H69.4643V6.94733H63.7891Z" fill="url(#paint0_linear_1655_460)" />
                <path d="M63.7891 29.0527V1.89478L74.3793 1.89478C76.4152 1.89478 78.1496 2.28818 79.5826 3.07498C81.0156 3.85294 82.1079 4.93589 82.8593 6.32384C83.6195 7.70296 83.9996 9.29424 83.9996 11.0977C83.9996 12.9011 83.6151 14.4924 82.8462 15.8715C82.0773 17.2507 80.9632 18.3248 79.504 19.0939C78.0535 19.863 76.2972 20.2476 74.2351 20.2476H67.4852V15.6461H73.3176C74.4099 15.6461 75.3098 15.456 76.0176 15.0759C76.7341 14.6869 77.2671 14.1521 77.6166 13.4714C77.9749 12.7818 78.154 11.9906 78.154 11.0977C78.154 10.196 77.9749 9.40917 77.6166 8.73729C77.2671 8.05658 76.7341 7.53057 76.0176 7.15927C75.3011 6.77913 74.3924 6.58906 73.2914 6.58906L69.4643 6.58906V29.0527H63.7891Z" fill="url(#paint1_linear_1655_460)" />
                <rect x="87.1572" y="10.1052" width="6.31579" height="24" rx="0.842105" fill="url(#paint2_linear_1655_460)" />
                <rect x="87.1572" y="1.89478" width="6.31579" height="6.31579" rx="0.842105" fill="url(#paint3_linear_1655_460)" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0 3.36842C0 1.50809 1.50809 0 3.36842 0H56C57.8603 0 59.3684 1.50809 59.3684 3.36842V32.6316C59.3684 34.4919 57.8603 36 56 36H3.36842C1.50809 36 0 34.4919 0 32.6316V3.36842ZM56.7755 11.7776L39.4644 29.0886L37.0826 31.4705L34.7007 29.0886L27.3022 21.6901L32.0659 16.9265L37.0826 21.9431L52.0118 7.01392L56.7755 11.7776ZM9.47363 6.31592V29.6843H14.2621V22.1079H18.2875C20.0274 22.1079 21.5093 21.777 22.7331 21.1152C23.9643 20.4534 24.9043 19.5291 25.5531 18.3424C26.2019 17.1558 26.5263 15.7865 26.5263 14.2347C26.5263 12.6829 26.2056 11.3137 25.5641 10.127C24.9301 8.93269 24.0085 8.00085 22.7995 7.33144C21.5904 6.65443 20.1269 6.31592 18.4091 6.31592H9.47363ZM17.5134 18.1485H14.2621V10.3552H17.4912C18.4202 10.3552 19.1869 10.5187 19.7915 10.8458C20.396 11.1653 20.8457 11.6179 21.1406 12.2037C21.4429 12.7818 21.594 13.4588 21.594 14.2347C21.594 15.003 21.4429 15.6838 21.1406 16.2772C20.8457 16.8629 20.396 17.3231 19.7915 17.6578C19.1943 17.9849 18.4349 18.1485 17.5134 18.1485Z" fill="url(#paint4_linear_1655_460)" />
                <defs>
                  <linearGradient id="paint0_linear_1655_460" x1="86.6397" y1="34.1052" x2="64.3278" y2="34.9392" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FFC039" />
                    <stop offset="0.8291" stop-color="#FFD95E" />
                  </linearGradient>
                  <linearGradient id="paint1_linear_1655_460" x1="86.6397" y1="1.89478" x2="64.3278" y2="1.06075" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FFC039" />
                    <stop offset="0.8291" stop-color="#FFD95E" />
                  </linearGradient>
                  <linearGradient id="paint2_linear_1655_460" x1="94.2981" y1="10.1052" x2="87.3171" y2="10.0129" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FFC039" />
                    <stop offset="0.8291" stop-color="#FFD95E" />
                  </linearGradient>
                  <linearGradient id="paint3_linear_1655_460" x1="94.2981" y1="1.89478" x2="87.3334" y2="1.54494" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FFC039" />
                    <stop offset="0.8291" stop-color="#FFD95E" />
                  </linearGradient>
                  <linearGradient id="paint4_linear_1655_460" x1="67.1238" y1="3.21199e-06" x2="1.93831" y2="-5.39964" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FFC039" />
                    <stop offset="0.8291" stop-color="#FFD95E" />
                  </linearGradient>
                </defs>
              </svg>
              <p>Address: <br />
              Anna Road,<br /> Chennai - 600002.</p>
              <a href="tel:+919345910837">+(91) 93459 10837</a>
              <br />
              <a href="https://www.paybi.in" target="_blank"  rel="noreferrer">www.PayBi.in</a>

            </div>
          </div>
          <div className="col-md-2 col-6">
            <div className="quick_link_all">
              <h2>Quick Links</h2>
              <ul>
                <li>Home
                </li>
                <li>Features</li>
                <li>Pricing</li>
                <li>Blog</li>
                <li> <a href="/contactus">Contact Us </a></li>
                <li>FAQs</li>
              </ul>
            </div>
          </div>
          <div className="col-md-2 col-6">
            <div className="quick_link_all">
              <h2>Company Info </h2>
              <ul>
                <li><a href="/aboutus"> About Us</a>
                </li>
                <li>Careers</li>
                <li>Press</li>
                <li>Partner with Us</li>
                <li>Investor Relations</li>
              </ul>
            </div>
          </div>
          <div className="col-md-2 col-6">
            <div className="quick_link_all">
              <h2>Policies  </h2>
              <ul>
                <li>Privacy Policy                </li>
                <li>Terms & Conditions</li>
                <li>Security</li>

              </ul>
            </div>
          </div>
          <div className="col-md-2 col-6">
            <div className="quick_link_all">
              <h2>Payments  </h2>
              <ul>
                <li>Visa</li>
                <li>Mastercard</li>
                <li>Rupay</li>
                <li>UPI</li>


              </ul>
            </div>
          </div>
          <div className="col-md-2 col-12">
            <div className="quick_link_all">
              <h2>Social  </h2>
              <div className="foot_svgs">
                <a href="/"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <g clip-path="url(#clip0_1655_447)">
                    <path d="M15.9965 3.985H18.1875V0.169C17.8095 0.117 16.5095 0 14.9955 0C11.8365 0 9.67252 1.987 9.67252 5.639V9H6.18652V13.266H9.67252V24H13.9465V13.267H17.2915L17.8225 9.001H13.9455V6.062C13.9465 4.829 14.2785 3.985 15.9965 3.985Z" fill="#E3E1D8" />
                  </g>
                  <defs>
                    <clipPath id="clip0_1655_447">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg></a>
                <a href="/"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <g clip-path="url(#clip0_1655_449)">
                    <path d="M18 0H6C2.64 0 0 2.64 0 6V18C0 21.36 2.64 24 6 24H18C21.36 24 24 21.36 24 18V6C24 2.64 21.36 0 18 0ZM21.6 18C21.6 20.04 20.04 21.6 18 21.6H6C3.96 21.6 2.4 20.04 2.4 18V6C2.4 3.96 3.96 2.4 6 2.4H18C20.04 2.4 21.6 3.96 21.6 6V18Z" fill="#E3E1D8" />
                    <path d="M12 6C8.64 6 6 8.64 6 12C6 15.36 8.64 18 12 18C15.36 18 18 15.36 18 12C18 8.64 15.36 6 12 6ZM12 15.6C9.96 15.6 8.4 14.04 8.4 12C8.4 9.96 9.96 8.4 12 8.4C14.04 8.4 15.6 9.96 15.6 12C15.6 14.04 14.04 15.6 12 15.6Z" fill="#E3E1D8" />
                    <path d="M17.9998 7.20005C18.6625 7.20005 19.1998 6.66279 19.1998 6.00005C19.1998 5.33731 18.6625 4.80005 17.9998 4.80005C17.3371 4.80005 16.7998 5.33731 16.7998 6.00005C16.7998 6.66279 17.3371 7.20005 17.9998 7.20005Z" fill="#E3E1D8" />
                  </g>
                  <defs>
                    <clipPath id="clip0_1655_449">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg></a>
                <a href="/"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path d="M21.6004 21.5999V14.5679C21.6004 11.1119 20.8564 8.47192 16.8244 8.47192C14.8804 8.47192 13.5844 9.52792 13.0564 10.5359H13.0084V8.78392H9.19238V21.5999H13.1764V15.2399C13.1764 13.5599 13.4884 11.9519 15.5524 11.9519C17.5924 11.9519 17.6164 13.8479 17.6164 15.3359V21.5759H21.6004V21.5999Z" fill="#E3E1D8" />
                  <path d="M2.71191 8.78394H6.69591V21.5999H2.71191V8.78394Z" fill="#E3E1D8" />
                  <path d="M4.70439 2.3999C3.43239 2.3999 2.40039 3.4319 2.40039 4.7039C2.40039 5.9759 3.43239 7.0319 4.70439 7.0319C5.97639 7.0319 7.00839 5.9759 7.00839 4.7039C7.00839 3.4319 5.97639 2.3999 4.70439 2.3999Z" fill="#E3E1D8" />
                </svg></a>
              </div>

            </div>
          </div>


        </div>
        <div className="footer_reserve_con">
          <p>© 2024 PayBi. All rights reserved.</p>
        </div>

      </div>

    </div>
  )
}

export default Newfooter
