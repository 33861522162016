import React, { useEffect } from 'react'
import Newnavbar from './Newnavbar'
import Newfooter from './Newfooter'
import power from '../Pages/Newimages/empowering.png'
import AOS from 'aos';

const Newcontact = () => {
    useEffect(() => {
        AOS.init({
            duration: 1000,
            easing: 'ease-in-out',
            once: false,
        });
    }, []);
    return (
        <div>
            <Newnavbar />
            <div className="contact_banner_all">
                <div className="new_about_ban_des container mx-auto">
                    <h2>Get in Touch with PayBi  We’re Here to Help!</h2>
                    <p>Have a question or need assistance? Our team is ready to help you with anything you need. Whether you’re looking for support or want to know more about PayBi’s features, reach out to us anytime. We’re just a message away</p>
                </div>
            </div>
            <div className="new_get_in_touch my-5">
                <div className="row g-5 container mx-auto">
                    <div className="col-md-7 col-12 new_con_form">
                        <h1>Get in touch with us</h1>
                        <p>Drop your email and Connect with us, and we’ll help bring your ideas to life!</p>
                        <form>
                            <div className="form-group">
                                <input
                                    type="text"
                                    className="form-control"
                                    id="email"
                                    placeholder="Enter your Mail"
                                />
                            </div>
                            <div className="form-group">
                                <input
                                    type="email"
                                    className="form-control"
                                    id="name"
                                    placeholder="Enter your Name"
                                />
                            </div>
                            <button type="submit" >Submit</button>
                        </form>
                    </div>

                    <div className="col-md-5 col-12">
                        <img src={power} alt="Edge" className="img-fluid"  data-aos="zoom-in-up"  />

                    </div>

                </div>
            </div>
            <Newfooter />

        </div>
    )
}

export default Newcontact
