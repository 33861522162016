import React, { useEffect } from 'react'
import Newnavbar from './Newnavbar'
import Newfooter from './Newfooter'
import mission from '../Pages/Newimages/mission.png'
import story from '../Pages/Newimages/story.png'
import manage from '../Pages/Newimages/about_manage.png'
import AOS from 'aos';




const Newcontactus = () => {
    useEffect(() => {
        AOS.init({
            duration: 1000,
            easing: 'ease-in-out',
            once: false,
        });
    }, []);
    return (
        <div>
            <Newnavbar />
            <div className="new_about_banner_img">
                <div className="new_about_des container mx-auto">
                    <h2>Simplifying Your Financial <br /> World with Paybi</h2>
                    <p>we make managing money easy, safe, and hassle-free. Whether it’s payments, tracking expenses, or budgeting, our platform gives you full control, anytime, anywhere. Join thousands of Indians already simplifying their finances with PayBi</p>
                    <button type="button"> Get Started <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                        <path d="M11.666 19.8334L17.4993 14L11.666 8.16669" stroke="black" stroke-width="2.33377" stroke-linecap="round" stroke-linejoin="round" />
                    </svg></button>
                </div>
            </div>


         <div className="mission_story_all">
            <div className="mission_story_des container mx-auto">

                <div className="mission_des_all">
                    <div className="row g-5">
                        <div className="col-md-3">
                        <img src={mission} alt="Edge" className="img-fluid" />

                        </div>
                        <div className="col-md-9">
                            <div className="mission_des">
                                <h2>“Our Mission</h2>
                                <p>At PayBi, we empower individuals and businesses with simple, secure, and accessible financial solutions. Our platform makes managing money effortless, helping users take control of their finances and make smarter decisions. We are dedicated to building a financially inclusive India, ensuring seamless access to intuitive tools and insights.
                                    <br />
                                    <br />
                                    Whether it’s tracking expenses or streamlining business finances, PayBi is here to simplify the process. Accessible anytime, anywhere, we’re transforming how finances are managed—making it easier for everyone to grow and thrive.
                                </p>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="story_des_all">
                    <div className="story_des container">
                        <div className="story_img">
                        <img src={story} alt="Edge" className="img-fluid" data-aos="zoom-in-up"/>

                        </div>
                      <h2>“Our Story</h2>
                      <p>PayBi was born out of a vision to simplify financial management for every Indian. We recognized the challenges of keeping track of expenses, making secure payments, and budgeting effectively in today’s fast-paced world. Our goal is to provide an easy-to-use platform that empowers people to take control of their finances with confidence. From everyday transactions to long-term financial planning, PayBi is here to make managing money simpler and more accessible for everyone across India.</p>
                    </div>
                </div>

            </div>

         </div>

         <div className="about_manage_all">
            <h2>Manage Finances Effortlessly on <br /> Web and Mobile</h2>
            <div className="manage_img">
            <img src={manage} alt="Edge" className="img-fluid" data-aos="zoom-in-up" />

            </div>

         </div>

            <Newfooter />
        </div>
    )
}

export default Newcontactus
